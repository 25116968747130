jQuery(document).ready(function($) {

    //slider
    $('#single-slider').slick({
        dots: true,
        arrows: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows:  $('#slider-wrapper .arrows'),
    });

    /**
     * Меню для мобильного
     */
    $('.btn-menu').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active').next().slideToggle()
    })



});